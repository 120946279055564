<template lang="html">
    <section class="home investor features">
        <section class="slider-full">
            <div class="slide ">
                <div class="d-flex jcsb container-slide container aic">
                    <div class="div-text ">
                        <Waypoint>
                            <h1 class="title-big -whiteC">
                            Quick video from R-SAMi founders.<br>
                            Here you can discover the history of R-SAMi
                            </h1>

                        </Waypoint>
                        <Waypoint>
                            <a href=" https://calendly.com/r-sami-demo/investor-relations" target="_blank" class="btn-destacado blue">Request INFO</a>
                        </Waypoint>

                    </div>

                    <div class="container d-flex aic jcc">

                        <div class="div-img">
                            <div class="preview-video" @click="activeVideo" v-show="maskVideo == true"></div>
                            <YouTube src="https://www.youtube.com/embed/w04C8khLjW0" ref="youtube" width="100%" height="100%" />

                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="driver-management container-investor">
            <div class="content-driver">
                <EditorJs v-if="investor" :object="investor.body" />
            </div>
        </section>
        <section class="interesting" ref="interesting" id="interesting">
            <h2 class="title-big  -blueDarkC">
                Interesting news for Investors
            </h2>
            <section class="blog">
                <div class="container grid-3" v-if="store.getEntries(this.page)">
                    <router-link :to="{
                        name: 'blog-page',
                        params: {
                            lang: $i18n.locale,
                            slug: entryData.slug,
                        }
                    }" class="grid entry-view" v-for="(entryData, key ) in store.getEntries(this.page)" :key="key">
                        <div class="img-container" :data-content="$t('web.moreinfo')">
                            <img :src="entryData.image.url" alt="imagen de blog">
                        </div>
                        <p class="title-semi-medium uppercase">

                            {{ entryData.name }}
                        </p>
                        <p class="date">{{ currentDateTime(entryData.publish_date) }}</p>
                        <p class="description">
                            {{ entryData.header }}
                        </p>
                    </router-link>
                </div>
                <loading :active='isLoading' :loader='"none"' />
            </section>

        </section>

        <section class="reserva-demo ">
            <div class="container">
                <div class="content">
                    <p>If you want t o request a meeting to discuss investment opportunities at<br> R-SAMi:</p>
                    <a target="_blank" href="https://calendly.com/r-sami-demo/investor-relations " class="btn-cta"><strong>Book your time with us here</strong><img src="/img/flecha-blanca.svg" alt="flecha blanca"></a>
                </div>
                <div class="content">
                    <img src="/img/animacion-demo.svg" alt="animación demo">
                </div>
            </div>
        </section>
        <newsletter></newsletter>
    </section>
</template>

<script lang="js">

    import moment from 'moment'
    import LineBlue from '@/lotties/line_1_blue.json'
    import LineBlue2 from '@/lotties/line_2_blue.json'
    import { Waypoint } from 'vue-waypoint'
    import Loading from 'vue-loading-overlay';
    import { contentStore } from '@/stores/contents'
    const store = contentStore();
    import newsletter from '@/components/newsletter'
    import YouTube from 'vue3-youtube'
    import EditorJs from "@/components/editorJs.vue"

    export default {
        name: 'home',
        props: [],
        components: {
            Waypoint,
            YouTube,
            Loading,
            'newsletter': newsletter,
            EditorJs
        },
        data() {
            return {
                animationState: {
                    line: false,
                    line2: false,
                },

                LineBlue,
                LineBlue2,
                maskVideo: true,
                videoOn: true,

            }
        },

        mounted() {

            window.addEventListener('scroll', this.onScroll)
            this.store.loadPageFixed({
                slug: 'investor'
            });
            this.store.loadEntriesInvestor(
                {
                    page: this.page,
                }
            ).then(
                this.isLoading = false
            );
        },

        setup() {
            return {
                store,
                getEntries: store.getEntries,
            }
        },
        computed: {
            page() {
                return store.getEntriesPage
            },
            investor() {
                return this.store.getPageFixed('investor');
            },

        },
        methods: {

            onScroll() {
                let t = this
                let contadorSection = document.querySelector('#contadores')
                if (contadorSection == null) {
                    return
                }
                var bounding = contadorSection.getBoundingClientRect();
                if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
                    window.removeEventListener('scroll', this.onScroll)
                    document.querySelectorAll('#contadores .number').forEach(function (element) {
                        let max = parseFloat(element.innerHTML)
                        element.innerHTML = 0
                        t.counter(max, element, 0)
                    });
                }

            },
            counter(max, element, number) {
                let t = this
                if (number < max) {
                    number += Math.round(max / 10)
                    if (number > max) {
                        number = max
                    }
                    element.innerHTML = Intl.NumberFormat().format(number)
                    setTimeout(() => {
                        t.counter(max, element, number)
                    }, 150);
                }
            },
            currentDateTime(date) {
                return moment(date).locale('en').format('LL')
            },

            onChange(waypointState) {
                if (this.animationState[waypointState.el.id] === false) {
                    this.animationState[waypointState.el.id] = waypointState.going == 'IN'
                }
            },
            activeVideo() {
                this.maskVideo = false
                this.videoOn = true
                this.$refs.youtube.playVideo()

            },
            onPlayerStateChange(event) {
                console.log(event)
                if (event.data === 0) {
                    this.maskVideo = true
                }
            }

        },
        watch: {
            "$i18n.locale"() {

                this.videoOn = false
                this.maskVideo = true
            },
            '$route'() {
                location.reload()

            },
            "store.lang": function (newLang) {
                this.changePages(1);
            }
        }
    }

</script>
<style>

</style>